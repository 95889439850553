









import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class GasMileage extends Vue {
  @Prop({ type: Number, required: true, default: () => 0 }) trip!: number
  @Prop({ type: Number, required: true, default: () => 0 }) fuel!: number

  get mileage (): string {
    if (!this.fuel) return '0Km/L'
    return `${(this.trip / this.fuel).toFixed(2)}Km/L`
  }

  get text (): string {
    return `${this.trip.toFixed(2)}Km | ${this.fuel.toFixed(2)}L`
  }
}
