














import { Component, Vue, Prop } from 'vue-property-decorator'
import { Day } from '@/models/day'
import moment from '@/plugins/moment'
import { ApexOptions } from 'apexcharts'

@Component<DashboardCardDayChart>({
  components: { }
})
export default class DashboardCardDayChart extends Vue {
  @Prop({ type: Array, required: true, default: () => [] }) items!: Day[]

  get title (): string {
    return `${moment().month() + 1}월 위험운전`
  }

  get lastItems (): Day[] {
    return this.items.slice(-7)
  }

  get chartOptions (): ApexOptions {
    return {
      chart: {
        id: 'vuechart-example',
        width: '100%'
      },
      xaxis: {
        // type: 'datetime',
        categories: this.items.map(item => {
          return moment(item.firstSecond.time).format('D')
        }),
        labels: {
          datetimeFormatter: {
            year: 'yyyy',
            month: 'MMM \'yy',
            day: 'dd MMM',
            hour: 'HH:mm'
          }
        }
      },
      // fill: { type: 'gradient' },
      stroke: {
        curve: 'smooth'
      },
      markers: {
        size: 5
      }
    }
  }

  get series (): ApexOptions['series'] {
    const ds = [
      {
        name: '과속',
        data: this.items.map(item => item.driveScore.overSpeed)
      },
      {
        name: '장기과속',
        data: this.items.map(item => item.driveScore.longOverSpeed)
      },
      {
        name: '급가속',
        data: this.items.map(item => item.driveScore.fastAcceleration)
      },
      {
        name: '급감속',
        data: this.items.map(item => item.driveScore.fastDeceleration)
      },
      {
        name: '급진로변경',
        data: this.items.map(item => item.driveScore.fastRouteChange)
      },
      {
        name: '급회전',
        data: this.items.map(item => item.driveScore.fastTurn)
      }
    ]
    return ds
  }
}
