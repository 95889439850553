

















import { Component, Vue, Prop } from 'vue-property-decorator'
import { Trip } from '@/models/trip'
import moment from '@/plugins/moment'
import TimeDuration from '@/components/common/TimeDuration.vue'

interface Total {
  trip: number;
  time: number;
  fuel: number;
}

@Component({ components: { TimeDuration } })
export default class DashboardCardDayMonthTime extends Vue {
  @Prop({ type: Array, required: true, default: () => [] }) items!: Trip[]

  get title (): string {
    return `${moment().month() + 1}월 주행 시간`
  }

  get total (): Total {
    const initialValue: Total = {
      trip: 0, time: 0, fuel: 0
    }
    const t = this.items.reduce((accumulator, currentValue) => {
      const time = moment(currentValue.lastSecond.time).diff(moment(currentValue.firstSecond.time), 'seconds')
      accumulator.time += time
      accumulator.trip += currentValue.lastSecond.trip
      accumulator.fuel += currentValue.lastSecond.fuel
      return accumulator
    }, initialValue)
    return t
  }
}
