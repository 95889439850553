




















import { Component, Vue, Prop } from 'vue-property-decorator'
import { Day } from '@/models/day'
import TimeDuration from '@/components/common/TimeDuration.vue'
import GasMileage from '@/components/common/GasMileage.vue'

interface Total {
  trip: number;
  fuel: number;
}

@Component({ components: { TimeDuration, GasMileage } })
export default class DashboardCardDayTotalMileage extends Vue {
  @Prop({ type: Array, required: true, default: () => [] }) items!: Day[]

  get item (): Day {
    return this.items.slice(-1)[0]
  }

  get total (): Total {
    if (!this.item) return { trip: 0, fuel: 0 }
    return {
      trip: this.item.lastSecond.totalTrip,
      fuel: this.item.lastSecond.totalFuel
    }
  }
}
