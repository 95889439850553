

















































import { Component, Vue, Prop } from 'vue-property-decorator'
import { Day } from '@/models/day'

@Component
export default class DashboardCardDayScore extends Vue {
  @Prop({ type: Array, required: true, default: () => [] }) items!: Day[]

  get lastTwoItems (): Day[] {
    if (this.items.length < 2) return []
    return this.items.slice(-2)
  }

  get totalScores (): number[] {
    return this.lastTwoItems.map(item => {
      return 100 - Object.values(item.driveScore).reduce((a, b) => a + b)
    })
  }

  get delta (): number {
    if (this.totalScores.length < 2) return 0
    return this.totalScores[1] - this.totalScores[0]
  }

  get beforeScore (): number {
    if (this.totalScores.length < 1) return 0
    return this.totalScores[0]
  }

  get currentScore (): number {
    if (this.totalScores.length < 2) return 0
    return this.totalScores[1]
  }
}
