













































import { Component, Vue, Prop } from 'vue-property-decorator'
import { Day } from '@/models/day'
import { ApexOptions } from 'apexcharts'
import { DriveScore } from 'functions/src/types/vehicle/drive-score'
import moment from '@/plugins/moment'

@Component<DashboardCardDayRadar>({
})
export default class DashboardCardDayRadar extends Vue {
  @Prop({ type: Array, required: true, default: () => [] }) items!: Day[]

  selectAverage = false

  get currentMonthTitle (): string {
    return `${moment().month() + 1}월 평균`
  }

  get lastItem (): Day {
    return this.items.slice(-1)[0]
  }

  get chartOptions (): ApexOptions {
    return {
      chart: {
        id: 'vuechart-example',
        width: '100%'
      },
      labels: ['과속', '장기과속', '급가속', '급감속', '급진로변경', '급회전'],
      fill: { type: '' },
      stroke: {
        show: true,
        width: 5,
        colors: ['#344955', '#4A6572'],
        dashArray: 0
      },
      markers: {
        size: 5
      },
      yaxis: {
        min: 0,
        max: 100,
        tickAmount: 5
      }
      // dataLabels: {
      //   enabled: true
      // }
    }
  }

  get seriesData (): number[] {
    const data: number[] = new Array(6).fill(0)

    if (this.selectAverage) {
      const score: DriveScore = {
        overSpeed: 0,
        longOverSpeed: 0,
        fastAcceleration: 0,
        fastDeceleration: 0,
        fastRouteChange: 0,
        fastTurn: 0
      }
      this.items.forEach((item) => {
        for (const [key, value] of Object.entries(item.driveScore)) {
          score[key] += value
        }
      })
      console.log(score)

      Object.values(score).forEach((val, i) => {
        const v = Math.floor((100) - (val / this.items.length))
        data.splice(i, 1, v)
      })
    } else {
      if (!this.lastItem) return data.fill(0)
      Object.values(this.lastItem.driveScore).forEach((val, i) => {
        const v = Math.floor(100 - val)
        data.splice(i, 1, v)
      })
    }

    return data
  }

  get series (): ApexOptions['series'] {
    const ds = [
      {
        name: '점수',
        data: this.seriesData
      }
    ]
    return ds
  }

  get seriesScore (): number {
    return Math.floor(this.seriesData.reduce((a, b) => a + b) / this.seriesData.length)
  }
}
