









































import { Component, Vue, Prop } from 'vue-property-decorator'
import firebase from '@/plugins/firebase'
import dayCollection, { Day } from '@/models/day'
import deviceCollection, { Device } from '@/models/device'
import moment from '@/plugins/moment'
import DashboardCardDayScore from '@/components/common/DashboardCardDayScore.vue'
import DashboardCardDayMonthTime from '@/components/common/DashboardCardDayMonthTime.vue'
import DashboardCardDayMonthMileage from '@/components/common/DashboardCardDayMonthMileage.vue'
import DashboardCardDayTotalMileage from '@/components/common/DashboardCardDayTotalMileage.vue'
import DashboardCardDayChart from '@/components/common/DashboardCardDayChart.vue'
import DashboardCardDayRadar from '@/components/common/DashboardCardDayRadar.vue'

@Component<DashboardLayoutDay>({
  components: {
    DashboardCardDayScore,
    DashboardCardDayMonthTime,
    DashboardCardDayMonthMileage,
    DashboardCardDayTotalMileage,
    DashboardCardDayChart,
    DashboardCardDayRadar
  },
  mounted () {
    this.fetch()
  }
})
export default class DashboardLayoutDay extends Vue {
  @Prop({ type: String, required: true }) phoneNumber!: string
  loading = true
  empty = true
  docs: firebase.firestore.QueryDocumentSnapshot<Day>[] = []

  get deviceRef (): firebase.firestore.DocumentReference<Device> {
    return deviceCollection.doc(this.phoneNumber)
  }

  get ref (): firebase.firestore.Query<Day> {
    const currentDate = moment()
    return dayCollection.where('device', '==', this.deviceRef)
      .where('firstSecond.time', '>=', currentDate.startOf('months').utcOffset(9).toISOString())
      .where('firstSecond.time', '<', currentDate.endOf('months').utcOffset(9).add(1, 'days').toISOString())
      .orderBy('firstSecond.time', 'asc')
      .limit(400)
  }

  get items (): Day[] {
    return this.docs.map(doc => doc.data())
  }

  get xs (): boolean {
    return this.$vuetify.breakpoint.xs
  }

  async fetch (): Promise<void> {
    if (!this.phoneNumber) return
    try {
      this.loading = true
      const sn = await this.ref.get()
      this.empty = sn.empty
      if (sn.empty) return
      this.docs = sn.docs
    } finally {
      this.loading = false
    }
  }
}
