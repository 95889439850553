













































































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import firebase from '@/plugins/firebase'
import { Trip } from '@/models/trip'
import TimeDuration from '@/components/common/TimeDuration.vue'
import GasMileage from '@/components/common/GasMileage.vue'
import { DriveScore } from '@/../functions/src/types/vehicle/drive-score'
import moment from '@/plugins/moment'
import SecondsDialog from '@/components/common/SecondsDialog.vue'

export type Start = {
    date: string;
    time: string;
    year: number;
    month: number;
    day: number;
    hour: number;
    minute: number;
    weekday: number;
    hasDay: boolean;
    hasTime: boolean;
    past: boolean;
    present: boolean;
    future: boolean;
}
export type End = {
    date: string;
    time: string;
    year: number;
    month: number;
    day: number;
    hour: number;
    minute: number;
    weekday: number;
    hasDay: boolean;
    hasTime: boolean;
    past: boolean;
    present: boolean;
    future: boolean;
}
export type Event = {
  name: string;
  start: string;
  end: string;
  color: string;
  details: DriveScore;
  doc: firebase.firestore.QueryDocumentSnapshot<Trip>;
}
@Component<DashboardCardTripCalendar>({
  components: { TimeDuration, GasMileage, SecondsDialog },
  mounted () {
    this.calendarInstance.checkChange()
  }
})
export default class DashboardCardTripCalendar extends Vue {
  // @Prop({ type: Array, required: true, default: () => [] }) items!: Trip[]
  @Prop({ type: Array, required: true }) docs!: firebase.firestore.QueryDocumentSnapshot<Trip>[]

  @Watch('items')
  nameChanged (): void {
    if (this.start && this.end) this.updateRange({ start: this.start, end: this.end })
  }

  focus = ''
  type = 'month'
  today = ''
  typeToLabel = {
    month: '월',
    week: '주',
    day: '일',
    '4day': '4일'
  }

  start: Start | null = null
  end: End | null = null
  selectedEvent = {}
  selectedElement = null
  selectedOpen = false
  events: Event[] = []
  colors = ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1']
  names = ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party']

  get items (): Trip[] {
    return this.docs.map(doc => doc.data())
  }

  get xs (): boolean {
    return this.$vuetify.breakpoint.xs
  }

  get title (): string {
    const { start, end } = this
    if (!start || !end) {
      return ''
    }
    const startMonth = this.monthFormatter(start)
    const endMonth = this.monthFormatter(end)
    const suffixMonth = startMonth === endMonth ? '' : endMonth
    const startYear = start.year
    const endYear = end.year
    const suffixYear = startYear === endYear ? '' : endYear
    const startDay = start.day + this.nth(start.day)
    const endDay = end.day + this.nth(end.day)
    switch (this.type) {
      case 'month':
        return `${startMonth} ${startYear}`
      case 'week':
      case '4day':
        return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`
      case 'day':
        return `${startMonth} ${startDay} ${startYear}`
    }
    return ''
  }

  get monthFormatter (): any {
    return this.calendarInstance.getFormatter({
      timeZone: 'UTC', month: 'long'
    })
  }

  get firstTime (): string {
    const min = Math.min(...this.items.map(item => Number(moment(item.firstSecond.time).format('HHmm'))))
    const text = min.toString().padStart(4, '0')
    const r = text.substr(0, 2) + ':' + text.substr(2, 2)
    return r
  }

  viewDay ({ date }: { date: string}): void {
    this.focus = date
    this.type = 'day'
  }

  getEventColor (event: Event): string {
    return event.color
  }

  setToday (): void {
    this.focus = this.today
  }

  prev (): void {
    this.calendarInstance.prev()
  }

  next (): void {
    this.calendarInstance.next()
  }

  showEvent ({ nativeEvent, event }: { nativeEvent: any; event: Event}): void {
    const open = () => {
      this.selectedEvent = event
      this.selectedElement = nativeEvent.target
      setTimeout(() => (this.selectedOpen = true), 10)
    }
    if (this.selectedOpen) {
      this.selectedOpen = false
      setTimeout(open, 10)
    } else {
      open()
    }
    nativeEvent.stopPropagation()
  }

  updateRange ({ start, end }: {start: Start; end: End}): void {
    const events: Event[] = []
    // const min = new Date(`${start.date}T00:00:00`)
    // const max = new Date(`${end.date}T23:59:59`)
    this.docs.forEach((doc) => {
      const item = doc.data()
      let driveScore = 0
      for (const [key, value] of Object.entries(item.driveScore)) {
        driveScore += value
      }

      events.push({
        name: (100 - driveScore).toString() + '점',
        start: this.formatDate(moment(item.firstSecond.time).toDate(), true),
        end: this.formatDate(moment(item.lastSecond.time).toDate(), true),
        color: this.getColors(100 - driveScore),
        details: item.driveScore,
        doc: doc
      })
    })
    this.start = start
    this.end = end
    this.events = events
  }

  nth (d: number): string {
    return d > 3 && d < 21
      ? 'th'
      : ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][d % 10]
  }

  getColors (score: number): string {
    if (score >= 90) return 'success'
    if (score >= 80) return 'warning'
    if (score < 80) return 'error'
    return 'error'
  }

  rnd (a: number, b: number): number {
    return Math.floor((b - a + 1) * Math.random()) + a
  }

  formatDate (a: Date, withTime: boolean): string {
    return withTime
      ? `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()} ${a.getHours()}:${a.getMinutes()}`
      : `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}`
  }

  getColor (type: string): string {
    let iconColor = 'default'
    switch (type) {
      case 'overSpeed':
        iconColor = 'red'
        break
      case 'longOverSpeed':
        iconColor = 'pink'
        break
      case 'fastAcceleration':
        iconColor = 'orange'
        break
      case 'fastDeceleration':
        iconColor = 'cyan'
        break
      case 'fastRouteChange':
        iconColor = 'lime'
        break
      case 'fastTurn':
        iconColor = 'blue-grey'
        break
      default:
        break
    }
    return iconColor
    // return this.type === 'start' ? 'success' : 'error'
  }

  private get calendarInstance (): Vue & { prev: () => void; next: () => void; checkChange: () => void; getFormatter: (format: any) => any; updateTimes: () => void } {
    return this.$refs.calendar as Vue & { prev: () => void; next: () => void; checkChange: () => void; getFormatter: (format: any) => any; updateTimes: () => void }
  }
}
