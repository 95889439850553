







import { Component, Vue } from 'vue-property-decorator'
import DashboardLayoutDay from '@/components/common/DashboardLayoutDay.vue'
import DashboardLayoutTrip from '@/components/common/DashboardLayoutTrip.vue'

@Component<MonitorDashboardIndex>({
  components: {
    DashboardLayoutDay,
    DashboardLayoutTrip
  }
})
export default class MonitorDashboardIndex extends Vue {
  get phoneNumber (): string {
    return this.$route.params.id
  }

  get xs (): boolean {
    return this.$vuetify.breakpoint.xs
  }
}
